$transition: all 0.3s ease-in-out;

.Homepage {
  position: relative;
  width: 100%;
  height: 100vh;
}

.Homepage-calendar {
  max-width: 360px;
  width: 100%;
  position: relative;
  transition: $transition;
}

.start-button {
  font-size: 21px;
  line-height: 32px;
  color: #fff;
  max-width: 250px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}